import Component from '../lib/component.js';
import store from '../store/index.js';

import {cleanPrice, numberThousandSeparator} from '../../../utils';

export default class TotalMonthly extends Component {
    constructor() {
        super({
            store,
            element: document.querySelector('.monthly-summary-desktop')
        });
    }

    getPriceMonthly = () => {
        const { tariff, instalments, participation } = store.state;
        const { price, participation: tariffParticipation } = tariff;

        const fullParticipation = cleanPrice(participation);

        return Math.round((cleanPrice(price) - fullParticipation) / instalments);
    }

    getAccessoriesTotalPrice = () => {
        const { accessories, paymentType } = store.state;

        if (accessories && paymentType === 'rate') {
            return accessories.reduce((accumulator, currentValue) => accumulator + cleanPrice(currentValue.price), 0);
        }
        return 0;
    }

    getDevicePriceMonthly = () => {
        const { tariff, instalments, participation } = store.state;
        const { price } = tariff;

        return Math.round((cleanPrice(price) - participation) / instalments);
    }

    getOldPriceMonthly = () => {
        const { tariff, instalments, participation } = store.state;
        const { tariffOldPrice, participation: tariffParticipation } = tariff;

        const fullParticipation = cleanPrice(participation);

        return Math.round((cleanPrice(tariffOldPrice) - fullParticipation) / instalments);
    }

    getMonthlyTotal = () => {
        const { tariff, paymentType, insurance, instalments } = store.state;
        const { monthlyFee } = tariff;
        const { isSelected: isInsuranceSelected, insurancePrice, insurancePromotionActive } = insurance;

        let monthlyTotal = 0;
        if (paymentType === 'rate') {
            monthlyTotal += this.getDevicePriceMonthly();
            monthlyTotal += Math.round(this.getAccessoriesTotalPrice() / instalments);
        }

        if (monthlyFee) {
            monthlyTotal += cleanPrice(monthlyFee);
        }

        if (isInsuranceSelected && !insurancePromotionActive) {
            monthlyTotal += cleanPrice(insurancePrice);
        }

        return monthlyTotal;
    }

    getMonthlyTotalString =  () => {
        const { tariff, instalments, paymentType } = store.state;
        const { tariffOldPrice, monthlyFee, price, tariffMonthlyDevicePriceOld } = tariff;

        // const finalTariffOldPrice = Math.round(cleanPrice(tariffOldPrice) / instalments + cleanPrice(monthlyFee));
        const finalTariffOldPrice = cleanPrice(monthlyFee) + this.getOldPriceMonthly();

        // const oldTariffPriceAndHw = this.getDevicePriceMonthly();
        const oldTariffPriceAndHwInstallment = cleanPrice(this.getDevicePriceMonthly()) + cleanPrice(tariffMonthlyDevicePriceOld);

        let discountHTML = ``;

        // if(xmass2021) { //window.xmass2021 = xmass2021; a1_device_single_view.html

        // }

        let isBusy = false;

        /* NEO15 FEB PROMO */
        if(!isBusy && tariffMonthlyDevicePriceOld !== 0 && tariffMonthlyDevicePriceOld !== undefined && tariffMonthlyDevicePriceOld !== null) {
            if (paymentType === 'gotovina' && tariff.tariffMonthlyDevicePriceOld !== '0' && tariff.tariffMonthlyDevicePriceOld !== '' && tariff.tariffMonthlyDevicePriceOld !== null && tariff.tariffMonthlyDevicePriceOld !== undefined) {
                discountHTML = `
                    <div id="neo-discount-total" class="m-device-card__price-discount">
                        <span class="a-01-text__primary--xs lh-large" id="tariffPrice">${numberThousandSeparator(tariffMonthlyDevicePriceOld)} RSD</span>
                    </div>
                `
                isBusy = true;
            } else if (!isBusy && paymentType === 'rate' && tariff.tariffMonthlyDevicePriceOld !== '0' && tariff.tariffMonthlyDevicePriceOld !== '' && tariff.tariffMonthlyDevicePriceOld !== null && tariff.tariffMonthlyDevicePriceOld !== undefined) {
                discountHTML = `
                    <div id="neo-discount-total" class="m-device-card__price-discount">
                        <span class="a-01-text__primary--xs lh-large" id="tariffPrice">${numberThousandSeparator(oldTariffPriceAndHwInstallment)} RSD</span>
                    </div>
                `
                isBusy = true;
            }
        }

        if(!isBusy && tariffOldPrice !== '0' && price !== '1' && paymentType !== 'gotovina'){
            discountHTML = `
                <div id="xmass-discount-total" class="m-device-card__price-discount">
                    <span class="a-01-text__primary--xs lh-large" id="tariffPrice">${numberThousandSeparator(finalTariffOldPrice)} RSD</span>
                </div>
            `
            isBusy = true;
        }

        if(!isBusy){
            discountHTML = `
            <div id="fake-discount-total" class="m-device-card__price-discount visibility_hidden">
                <span class="a-01-text__primary--xs lh-large" id="tariffPrice">0 RSD</span>
            </div>
        `;
        }
        // else {
        //     discountHTML = `
        //         <div id="xmass-discount-total" class="m-device-card__price-discount">
        //             <span class="a-01-text__primary--xs lh-large" id="tariffPrice">${numberThousandSeparator(this.getPriceMonthly())} RSD</span>
        //         </div>
        //     `
        // }

        const monthlyTotal = this.getMonthlyTotal();
        return `
        ${discountHTML}
        ${numberThousandSeparator(monthlyTotal)} RSD`;
    }


    /**
     * React to state changes and render the component's HTML
     *
     * @returns {void}
     */
    render() {

        this.element.innerHTML = this.getMonthlyTotalString();

    }
}
