import {getParameterByName} from "./utils";

console.log('pageScroll.js loaded');
const pageScroller = () => {
  console.log('pageScroller started');
  const pagePart = getParameterByName('page-part');
  console.log('pageScroller pagePart', pagePart);
  if (pagePart) {
    let element = document.getElementById(pagePart);
    if (element) {
      element.scrollIntoView();
    } else {
      element = document.querySelector(`.${pagePart}`);
      console.log('pageScroller class pagePart', pagePart);
      if (element) {
        element.scrollIntoView();
      }
    }
  }
};

export default pageScroller;