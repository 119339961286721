import Component from '../lib/component.js';
import store from '../store/index.js';


export default class PaymentTypeLabel extends Component {
    constructor() {
        super({
            store,
            element: document.querySelector('.payment-type-label-desktop')
        });
    }

    /**
     * React to state changes and render the component's HTML
     *
     * @returns {void}
     */
    render() {
        const { paymentType, isTvOrTablet, tariff } = store.state;
        const { tariffCode } = tariff;
        if (paymentType === 'rate') {
            this.element.innerHTML = 'Plaćanje na rate';
        } else {
            if (isTvOrTablet) {
                this.element.innerHTML = `Uz prvi račun`;
                var tariffCardAddonTooltip = document.querySelector(`a[data-tariff-code="${tariffCode}"]`).parentElement.parentElement.parentElement.parentElement.querySelector('.p-tariff-card-addon__tooltip .a-01-text__primary--sm.lh-large');
                if (tariffCardAddonTooltip) {
                    tariffCardAddonTooltip.innerHTML = 'Uz prvi račun';
                }
            } else {
                this.element.innerHTML = 'Gotovina';
            }
        }
    }
}
