import Component from '../lib/component.js';
import store from '../store/index.js';

// import { cleanPrice, numberThousandSeparator } from '../../../utils';

export default class TariffCommunicationPromo extends Component {
    constructor(props) {
        super({
            store,
            element: document.querySelector('.tariff-communication-promo'),
        });

        this.props = props;
    }

    shouldShowDescription = () => {
        const { tariff, tariffCommunicationPromoList } = store.state;
        const { tariffCode } = tariff;

        if (tariffCommunicationPromoList && tariffCommunicationPromoList.includes(tariffCode)){
            return true;
        }

        return false;
    }


    /**
     * React to state changes and render the component's HTML
     *
     * @returns {void}
     */
    render() {
        if (!this.element) {
            return;
        }

        this.element.innerHTML = `
            ${this.shouldShowDescription() ? this.props.languageConsts['promoTariffDesc'] : ''}
        `;
    }
}

