import Component from '../lib/component.js';
import store from '../store/index.js';

export default class AccessoriesCount extends Component {
    constructor() {
        super({
            store,
            element: document.querySelector('.acc-item-count-desktop')
        });
    }

    getAccessoriesCount = () => {
        const { accessories } = store.state;

        if (accessories) {
            return accessories.length;
        }
        return 0;
    }


    /**
     * React to state changes and render the component's HTML
     *
     * @returns {void}
     */
    render() {

        this.element.innerHTML = `(${this.getAccessoriesCount()})`;

    }
}
