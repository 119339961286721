import {createElementFromHTML} from "./utils";

const LoaderController = (function () {
  let instance;
  function LoaderController() {
    this.init();
  }

  LoaderController.prototype.init = function () {

  };

  LoaderController.prototype.show = function () {
    const loaderHtml = `
<div id="page-preload" class="a1-loader">
  <div class="overlay-loader"></div>
  <div class='pop-up'>
    <div class="pin-preloader">
      <div class="Loader Loader-wrapper">
        <div class="Loader-container">
          <div class="Loader-box Loader-box--black"></div>
          <div class="Loader-box Loader-box--red"></div>
        </div>
      </div>
    </div>
  </div>
</div>`;
    document.body.appendChild(createElementFromHTML(loaderHtml));
  }

  LoaderController.prototype.hide = function () {
    const loader = document.getElementById('page-preload');
    if (loader) {
      loader.remove();
    }
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = new LoaderController();
      }
      return instance;
    }
  };
})();

export default LoaderController;