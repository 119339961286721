
/**
 * max 10 kom
 * [
 *      {
 *          accessoryType: 'AD_NEKIKOD',
 *          accessoryName: 'Osiguranje',
 *          accessoryPrice: 500,
 *      }
 * ]
 * @type {Array}
 * @private
 */

class Accessories {
    constructor(isGadget = false) {
        this._accessoryId = null;
        this._accessoryCode = null;
        this._accessoryComercialName = null;
        this._accessoryName = null;
        this._accessoryManufacturerName = null;
        this._accessoryType = null;
        this._accessoryPrice = null;
        this._accessoryImage = null;
        this._isGadget = isGadget;
        this._accessoryParticipation = null;
        this._priceCash = null;
    }

    getAll = () => ({
        accessoryId: this._accessoryId,
        accessoryCode: this._accessoryCode,
        accessoryComercialName: this._accessoryComercialName,
        accessoryType: this._accessoryType,
        accessoryManufacturerName: this._accessoryManufacturerName,
        accessoryName: this._accessoryName,
        accessoryPrice: this._accessoryPrice,
        accessoryImage: this._accessoryImage,
        accessoryParticipation: this._accessoryParticipation,
        isGadget: this._isGadget,
        priceCash: this._priceCash,
    });

    setAll = (accId, code, type, commercialName, manufacturerName, name, price, image, participation = 0, isGadget = false) => {
        this._accessoryId = accId;
        this._accessoryCode = code;
        this._accessoryComercialName = commercialName;
        this._accessoryType = type;
        this._accessoryName = name;
        this._accessoryManufacturerName = manufacturerName;
        this._accessoryPrice = price;
        this._accessoryImage = image;
        this._accessoryParticipation = participation;
        this._isGadget = isGadget;
    };

    setAllN = ({accId, code, type, commercialName, manufacturerName, name, price, priceCash, image, participation, isGadget}) => {
        this._accessoryId = accId;
        this._accessoryCode = code;
        this._accessoryComercialName = commercialName;
        this._accessoryType = type;
        this._accessoryName = name;
        this._accessoryManufacturerName = manufacturerName;
        this._accessoryPrice = price;
        this._accessoryImage = image;
        this._accessoryParticipation = participation;
        this._isGadget = isGadget;
        this._priceCash = priceCash;
    };


    get accessoryType() {
        return this._accessoryType;
    }

    set accessoryType(value) {
        this._accessoryType = value;
    }

    get accessoryName() {
        return this._accessoryName;
    }

    set accessoryName(value) {
        this._accessoryName = value;
    }

    get accessoryPrice() {
        return this._accessoryPrice;
    }

    set accessoryPrice(value) {
        this._accessoryPrice = value;
    }

    get accessoryPriceCash() {
        return this._priceCash;
    }

    set accessoryPriceCash(value) {
        this._priceCash = value;
    }

    get accessoryId() {
        return this._accessoryId;
    }

    set accessoryId(value) {
        this._accessoryId = value;
    }

    get accessoryCode() {
        return this._accessoryCode;
    }

    set accessoryCode(value) {
        this._accessoryCode = value;
    }

    get accessoryComercialName() {
        return this._accessoryComercialName;
    }

    set accessoryComercialName(value) {
        this._accessoryComercialName = value;
    }

    get accessoryManufacturerName() {
        return this._accessoryManufacturerName;
    }

    set accessoryManufacturerName(value) {
        this._accessoryManufacturerName = value;
    }
    get accessoryParticipation() {
        return this._accessoryParticipation;
    }
    set accessoryParticipation(value) {
        this._accessoryParticipation = value;
    }
    getParticipation = () => {
        return this._accessoryParticipation;
    }
}

export default Accessories;
