import Component from '../lib/component.js';
import store from '../store/index.js';

import { numberThousandSeparator } from '../../../utils';

export default class InsurancePriceMonthlyNoDiscount extends Component {
    constructor(selector) {
        super({
            store,
            element: document.querySelector(selector)
        });
    }

    /**
     * React to state changes and render the component's HTML
     *
     * @returns {void}
     */
    render() {
        const { insurance, tariff } = store.state;
        const { isSelected, insurancePrice, insurancePromotionActive } = insurance;
        const { tariffCode } = tariff;
        if (!insurancePromotionActive) {
            return;
        }

        this.element.innerHTML = `${numberThousandSeparator(insurancePrice)} RSD/mes`;

    }
}
