export default class PubSub {
    constructor() {
        this.events = {};
    }

    /**
     * Either create a new event instance for passed `event` name
     * or push a new callback into the existing collection
     *
     * @param {string} event
     * @param {function} callback
     * @returns {number} A count of callbacks for this event
     * @memberof PubSub
     */
    subscribe(event, callback) {
        
        let self = this;
        

        // Ako vec ne postoji event sa prosledjenim imenom setovan u nasoj kolekciji,
        // napravice novi i setovace ga na prazan niz
        if(!self.events.hasOwnProperty(event)) {
            self.events[event] = [];
        }
        
        // sad event sigurno postoji pa mozemo da push-ujemo callback bez brige
        return self.events[event].push(callback);
    }
    /**
     * If the passed event has callbacks attached to it, loop through each one
     * and call it
     *
     * @param {string} event
     * @param {object} [data={}]
     * @returns {array} The callbacks for this event, or an empty array if no event exits
     * @memberof PubSub
     */
    publish(event, data = {}) {
        let self = this;
        
        // Nema event-a za publish, pa vrati nista;
        if(!self.events.hasOwnProperty(event)) {
            return [];
        }
        
        // Pokupi sve subskripcije pojedinacno i poziva njihove callback-ove sa prosledjenim podacima
        return self.events[event].map(callback => callback(data));
    }
}