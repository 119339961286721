import Insurance from './Insurance';
import Tariff from './Tariff';
import Device from './Device';
import Accessories from './Accessories';

// import TariffAddon from './TariffAddon';

/**
 * PDPVirtualCart
 */
class PDPVirtualCart {
    constructor() {
        if (PDPVirtualCart._instance) {
            return PDPVirtualCart._instance;
        }

        PDPVirtualCart._instance = this;

        this._device = new Device();
        this._accessories = [];
        this._gadgets = [];
        this._instalments = 24;
        this._tariff = new Tariff();
        this._insurance = new Insurance();
        this._participation = null;

        this.init();
        return this;
    }

    init = () => {

    };

    setDevice = (type, code, manufacturer, name, color, mmid, price, instalments, participation, montlyFee, image) => {
        this._device.setAll(type, code, manufacturer, name, color, mmid, price, instalments, participation, montlyFee, image);
    };

    getDevice = () => this._device;

    doesAccessoryExists = (code, accId) => {
        let exists = true;
        if (this._accessories.length > 0) {
            for (let i = 0; i < this._accessories.length; i++) {
                if (this._accessories[i].accessoryCode === code && this._accessories[i].accessoryId === accId) {
                    exists = false;
                    break;
                }
            }
        }
        return exists;
    }

    pushAccesory = ({accId, code, type, commercialName, manufacturerName, name, price, priceCash, image, isGadget}) => {
        const accItem = new Accessories(isGadget);
        accItem.setAllN({
            accId,
            code,
            type,
            commercialName,
            manufacturerName,
            name,
            price,
            priceCash,
            image,
            isGadget,
        });

        let shouldAdd = this.doesAccessoryExists(code, accId);

        if (shouldAdd) {
            if (isGadget) {
                this._gadgets.push(accItem.getAll());
            } else {
                this._accessories.push(accItem.getAll());
            }
        }

    };
    getAccessories = () => {
        return this._accessories;
    };

    getAccCount = () => {
        return this._accessories.length;
    };

    removeAccesory = (code) => {
        this._accessories = this._accessories.filter(acc => acc.accessoryCode !== code);
    };

    setDeviceInstalments = (instalments) => {
        this._device.deviceInstalments = instalments;
    };

    setDeviceColor = (color, mmid) => {
        this._device.deviceColor = color;
        this._device.deviceMmid = mmid;
    };

    getDeviceColorData = () => {
        return {
            color: this._device.deviceColor,
            mmid: this._device.deviceMmid,
        }
    };

    setTariff = (tariffCode, price, priceCash, externalId, tariffname, name, montlyFee, montlyFeeWithoutDevice, properties, participation, minPriceForActivation, maxPriceForActivation, tariffCollection = null, oldMonthlyDevicePrice, tariffOldPrice, tariffOldPriceCash, tariffMonthlyPriceOld, tariffMonthlyDevicePriceOld, shortDescription) => {
        this._tariff.setAll(tariffCode, price, priceCash, externalId, tariffname, name, montlyFee, montlyFeeWithoutDevice, properties, participation, minPriceForActivation, maxPriceForActivation, tariffCollection, oldMonthlyDevicePrice, tariffOldPrice, tariffOldPriceCash, tariffMonthlyPriceOld, tariffMonthlyDevicePriceOld);
    };
    setTariffNew = ({
        tariffCode,
        price,
        priceCash,
        externalId,
        tariffName,
        name,
        monthlyFee,
        monthlyFeeWithoutDevice,
        properties,
        participation,
        minPriceForActivation,
        maxPriceForActivation,
        tariffCollection = null,
        oldMonthlyDevicePrice,
        tariffOldPrice,
        tariffOldPriceCash,
        tariffMonthlyPriceOld,
        tariffMonthlyDevicePriceOld,
        shortDescription,
        shortDescriptionWithDevice,
        shortDescriptionWithoutDevice,
    }) => {
        this._tariff.setAllNew({
            tariffCode,
            price,
            priceCash,
            externalId,
            tariffName,
            name,
            monthlyFee,
            monthlyFeeWithoutDevice,
            tariffProperties: properties,
            participation,
            minParticipationForActivation: minPriceForActivation,
            maxParticipationForActivation: maxPriceForActivation,
            tariffCollection,
            oldMonthlyDevicePrice,
            tariffOldPrice,
            tariffOldPriceCash,
            tariffMonthlyPriceOld,
            tariffMonthlyDevicePriceOld,
            shortDescription,
            shortDescriptionWithDevice,
            shortDescriptionWithoutDevice,
        })
    };
    getTariff = () => {
        return this._tariff.getAll();
    };
    clearTariff = () => {
        this.setTariff(null, null, null, null, null, null, null, null);
    };

    getDeviceColor = () => this._device.deviceMmid;

    setTariffProperties = (properties) => {
        this._tariff.setProperties(properties);
    }

    getAll = () => {
        return {
            device: this._device.getAll(),
            accessories: this._accessories,
            tariff: this._tariff.getAll(),
            insurance: this._insurance.getAll(),
            participation: this._participation,
            gadgets: this._gadgets,
        };
    };

    isForCash = () => {
        return !this._instalments;
    };

    getParticipation = () => this._participation;

    setParticipation = val => this._participation = val;

    clearInsurance = () => {
        this._insurance = new Insurance();
    }

    setInstalments = instalments => this._instalments = instalments;

    set instalments(value) {
        this._instalments = value;
    }

    get instalments() {
        return this._instalments;
    }

    get instance() {
        return this._instance;
    }

    set instance(value) {
        this._instance = value;
    }

    get insurance() {
        return this._insurance;
    }

    set insurance(value) {
        this._insurance = value;
    }

}

export default PDPVirtualCart;
