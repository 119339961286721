
/**
 * {
 *     insuranceType: 'BPO123',
 *     insuranceName: 'Osiguranje',
 *     insurancePrice: 500,
 *     insuranceParticipation: 15000,
 * }
 * @type {null}
 * @private
 */
class Insurance {
    constructor() {
        this._insuranceType = null;
        this._insuranceName = null;
        this._insurancePrice = null;
        this._insuranceParticipation = null;
        this._insurancePromotionActive = null;
        this._selected = false;
    }

    getAll = () => {
        return {
            insuranceType: this._insuranceType,
            insuranceName: this._insuranceName,
            insurancePrice: this._insurancePrice,
            insuranceParticipation: this._insuranceParticipation,
            insurancePromotionActive: this._insurancePromotionActive,
            isSelected: this._selected,
        };
    };

    setAll = (type, name, price, participation, insurancePromotionActive) => {
        this._insuranceType = type;
        this._insuranceName = name;
        this._insurancePrice = price;
        this._insuranceParticipation = participation
        this._insurancePromotionActive = insurancePromotionActive;
    };

    isSelected = () => {
        return this._selected;
    };

    toggleSelected = () => {
        this._selected = !this._selected;
    };
    setSelected = () => {
        this._selected = true;
    }


    get insuranceType() {
        return this._insuranceType;
    }

    set insuranceType(value) {
        this._insuranceType = value;
    }

    get insuranceName() {
        return this._insuranceName;
    }

    set insuranceName(value) {
        this._insuranceName = value;
    }

    get insurancePrice() {
        return this._insurancePrice;
    }

    set insurancePrice(value) {
        this._insurancePrice = value;
    }

    get insuranceParticipation() {
        return this._insuranceParticipation;
    }

    set insuranceParticipation(value) {
        this._insuranceParticipation = value;
    }
}

export default Insurance;