import Component from '../lib/component.js';
import store from '../store/index.js';
import {cleanPrice, simulateClick} from "../../../utils";
import PDPVirtualCart from "../../VirtualCart/PDPVirtualCart";

// import { numberThousandSeparator } from '../../../utils';

export default class Wrapper extends Component {
    constructor(selector) {
        super({
            store,
            element: document.querySelector(selector)
        });
    }

    // toggleAccessoriesBasket = () => {
    //     const accessoryBasketSectionClickable = document.querySelector('.tariff-accessories-close-button-clickable-desktop');
    //     if (accessoryBasketSectionClickable) {
    //         simulateClick(accessoryBasketSectionClickable);
    //     }
    // }

    // toggleAccessoryBasketSectionIfEmpty = () => {
    //     const { accessories } = store.state;
    //
    //     const accessoriesSectionElement = this.element.querySelector('.tariff-accessories-close-button-clickable-desktop');
    //     if (accessoriesSectionElement) {
    //         if (
    //             (accessories.length > 0 && !accessoriesSectionElement.classList.contains('expanded')) ||
    //             (accessories.length === 0 && accessoriesSectionElement.classList.contains('expanded'))
    //         ) {
    //             // this.toggleAccessoriesBasket();
    //         }
    //     }
    // }
    hideShow = ({ selectorsForElementsToHide, selectorsForElementsToShow }) => {
        if(selectorsForElementsToHide) {
            selectorsForElementsToHide.forEach(elSelector => {
                if (elSelector) {
                    const el = this.element.querySelector(elSelector);
                    if(el) {
                        el.classList.add('display_none');
                    }
                }
            });
        }
        if(selectorsForElementsToShow) {
            selectorsForElementsToShow.forEach(elSelector => {

                if (elSelector) {
                    const el = document.querySelector(elSelector);
                    if (el) {
                        el.classList.remove('display_none');
                    }
                }

            });
        }
    }
    changeHTMLDirectly = () => {
        this.handlePaymentType();
        this.handleTariff();
        this.setRangeInputMinAndMaxValues();
        this.handleAccessories();
        this.handlePromoBar();
        // this.toggleAccessoryBasketSectionIfEmpty();
    }

    handlePromoBar = () => {
        const { tariff } = store.state;
        const { tariffName } = tariff;
        const stickyPromoHeader = document.querySelector('.sticky-promo-header-desktop');
        const stickyPromoHeaders = document.querySelectorAll('.sticky-promo-header-desktop');

        // if ((tariffName === 'NEO tarife' || tariffName === 'интарифа' || tariffName === '65+ tarifa') && stickyPromoHeader) {
        //     stickyPromoHeader.classList.remove('display_none');
        // } else {
        //     if (stickyPromoHeader) {
        //         stickyPromoHeader.classList.add('display_none');
        //     }
        // }
        const stickyIds = ['old_price_promo','old_price_cash_promo','insurancePromo','platinumPromo','Neo24Promo','Spring24Promo'];
        stickyPromoHeaders.forEach(item => {
            if(!stickyIds.includes(item.id)){
                item.classList.add('display_none');
            }
        });


    }
    isDeviceEligibleForInstalments = () => {
        const { tariff } = store.state;
        const { price, minParticipationForActivation } = tariff;
        const cleanIntPrice = cleanPrice(price);
        let minParticipationForActivationClean = minParticipationForActivation;


        //
        // if (!minParticipationForActivation) {
        //     minParticipationForActivationClean = 2400;
        //
        // }
        if (this.isStandaloneTariff()) {
            return false;
        }

        return cleanIntPrice >= parseInt(minParticipationForActivationClean);

    }

    isBusiness = () => {
        const { isBusiness } = store.state;

        return isBusiness;
    }
    isBusinessAndTablet = () => {
        const { isBusinessAndTablet } = store.state;

        return isBusinessAndTablet;
    }

    isAccessoriesEmpty = () => {
        const { accessories } = store.state;
        return accessories.length === 0;
    }

    handleAccessories = () => {
        // const { accessories } = store.state;

        if (this.isAccessoriesEmpty()) {
            this.hideShow({
                selectorsForElementsToShow: [
                    '.not-selected-accessories-desktop',
                ],
                selectorsForElementsToHide: [
                    '.selected-accessories-desktop',
                ],
            });
        } else {
            this.hideShow({
                selectorsForElementsToShow: [
                    '.selected-accessories-desktop',
                ],
                selectorsForElementsToHide: [
                    '.not-selected-accessories-desktop',
                ],
            });
        }

    }

    handleTariff = () => {

        this.hideShow({
            selectorsForElementsToHide: [
                this.isDeviceEligibleForInstalments() ? null : '[data-input-id="placanje-rate-mobile"]',
            ],
            selectorsForElementsToShow: [
                this.isDeviceEligibleForInstalments() ? '[data-input-id="placanje-rate-mobile"]' : null,
            ],
        });

    }

    shouldForceCash = () => {

        if (this.isStandaloneTariff()) {
            return true;
        }

        if (!this.isDeviceEligibleForInstalments()) {
            return true;

        }

        if (this.isBusinessAndTablet()) {
            // const cashInstalmentsSwitchRadio = this.element.querySelector('#placanje-gotovina-mobile');
            const instalmentsContent = this.element.querySelector('.installments_type_content_desktop');
            if (instalmentsContent && !instalmentsContent.classList.contains('display_none')) {
                instalmentsContent.classList.add('display_none');
                setTimeout(() => {
                    instalmentsContent.classList.add('display_none');
                });
            }
            return true;
        }

        // const cashInstalmentsSwitchRadio = this.element.querySelector('#placanje-gotovina-mobile');
        //
        // if (cashInstalmentsSwitchRadio) {
        //     // simulateClick(cashInstalmentsSwitchRadio);
        //
        //
        // }
        return false;
    }

    handlePaymentType = () => {

        const { paymentType } = store.state;

        if (this.shouldForceCash()) {
            if (paymentType === 'rate') {
                store.dispatch('setPaymentType', 'gotovina');
            }
            const cashRadioButton = this.element.querySelector('#placanje-gotovina-mobile');

            if (cashRadioButton && !cashRadioButton.checked) {
                cashRadioButton.checked = true;
            }
            this.handleGotovina();
        } else {
            if (paymentType === 'rate') {
                this.handleRate();
            } else if (paymentType === 'gotovina') {
                this.handleGotovina();
            } else {
                console.warning('Payment type is not valid (STICKY STATE)');
            }
        }

        this.handleAccessoryPrices();
    }
    shouldHideParticipationSlider = () => {
        const { tariff } = store.state;
        const { maxParticipationForActivation, minParticipationForActivation } = tariff;

        return maxParticipationForActivation === minParticipationForActivation;
    }

    handleAccessoryPrices = () => {
        const { paymentType, instalments } = store.state;

        /**
         * Adjust accessory prices
         */
        const accessoriesPriceItems = document.querySelectorAll('.acc-price-item');
        if (accessoriesPriceItems) {
            accessoriesPriceItems.forEach(accessoryPriceItem => {
                const accPriceCash = accessoryPriceItem.getAttribute('data-acc-price-cash');
                const accPrice = accessoryPriceItem.getAttribute('data-acc-price');
                if (paymentType === 'rate') {
                    const monthlyPrice = Math.round(cleanPrice(accPrice) / instalments);
                    accessoryPriceItem.innerHTML = `${numberThousandSeparator(monthlyPrice)} RSD/mes`;
                } else if (paymentType === 'gotovina') {
                    accessoryPriceItem.innerHTML = `${numberThousandSeparator(accPriceCash)} RSD`;
                }
            });
        }
    }

    handleRate = () => {
        this.hideShow({
            selectorsForElementsToHide: [
                this.shouldHideParticipationSlider() ? '.ucesce-range-desktop' : null,
                '.acc_old_price_cash',
            ],
            selectorsForElementsToShow: [
                '.installments_type_content_desktop',
                '.participation-basket-label-desktop',
                '.acc_old_price',
                this.shouldHideParticipationSlider() ? null : '.ucesce-range-desktop',

            ],
        });

        const { isTvOrTablet, paymentType, tariff } = store.state;
        const { price: devicePriceFromTariff } = store.state;
        if (isTvOrTablet && paymentType !== 'rate' && parseInt(devicePriceFromTariff) > 0) {
            store.dispatch('setPaymentType', 'rate');

        }
    }

    handleGotovina = () => {
        this.hideShow({
            selectorsForElementsToHide: [
                '.installments_type_content_desktop',
                '.participation-basket-label-desktop',
                '.acc_old_price'
            ],
            selectorsForElementsToShow: [
                '.acc_old_price_cash',
            ],
        });

        const cashRadioButton = this.element.querySelector('#placanje-gotovina-mobile');

        if (cashRadioButton && !cashRadioButton.checked) {
            cashRadioButton.checked = true;
        }
    }

    getMinMaxAndValueForRangeInput = () => {
        const { tariff, participation, isAccessory } = store.state;
        const { participation: tariffParticipation, price, maxParticipationForActivation, minParticipationForActivation} = tariff;

        let intPrice = cleanPrice(price);

        const pdpVc = new PDPVirtualCart();
        let pdpVcData = pdpVc.getAll();

        let maxValueForRange = intPrice - minParticipationForActivation;
        let minValueForRange = intPrice - maxParticipationForActivation;
        if (maxParticipationForActivation > intPrice) {
            minValueForRange = 0;
        }

        if(pdpVcData.tariff.participation) {
            minValueForRange = pdpVcData.tariff.participation;
        }

        if (isAccessory) {
            minValueForRange = 0;
            maxValueForRange = intPrice - 4800;
        }



        return {
            minValueForRange,
            maxValueForRange,
            value: participation,
        }
    }

    setRangeInputMinAndMaxValues = () => {
        const { maxValueForRange, minValueForRange } = this.getMinMaxAndValueForRangeInput();

        const participationInputElement = this.element.querySelector('#ucesce');

        if (participationInputElement) {
            participationInputElement.min = `${minValueForRange}`;
            participationInputElement.max = `${maxValueForRange}`;
            // participationInputElement.value = `${minValueForRange}`;
        }
    }

    isStandaloneTariff = () => {
        const { tariff } = store.state;
        const { tariffCollection } = tariff;
        return tariffCollection === '5';
    }
    /**
     * This render SHOULD NOT change its element inner html because it holds whole sticky.
     * It should be used for directly working with DOM objects if dependent from state (toggling classes...)
     * WARNING: DON'T CHANGE THIS FUNCTION. Do your magic in changeHTMLDirectly function
     * @returns {void}
     */
    render() {
        this.changeHTMLDirectly();
        /**
         * WARNING: THIS SHOULD NEVER CHANGE ITS INNER HTML
         */
        // this.element.innerHTML = `
        //     ${numberThousandSeparator(participation)}
        // `;
    }
}
