import { createElementFromHTML } from './utils.js';

function getUrlParam(param) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get(param);
}
// create singleton class for debug toolbar
const DebugToolbar = (function () {
    let instance;
    let _isEnabled = getUrlParam('enable-toolbar') === '1';
    let _toolbar = null;
    let _isCollapsed = false;
    let _toolbarButton = null;
    let _isInitialized = false;
    let _toolbarContent = null;

    function DebugToolbar() {
        // this._isEnabled = true;
        // this._isCollapsed = false;
        // this._toolbar = null;
        this.init();
    }


    function _allowAddToCartButtonHTML() {
        let allowAddToCartHTML = '';
        if (document.body.classList.contains('pthclass_detalji')) {
            allowAddToCartHTML = `
            <a href="${document.location.href}&allow-add-to-cart=1">
                Allow add to cart
            </a>
        `
        }

        return allowAddToCartHTML;
    }
    function _removeNotAvailableModalButtonHTML() {
        let removeNotAvailableModalButtonHTML = '';
        if (document.body.classList.contains('pthclass_cart')) {
            removeNotAvailableModalButtonHTML = `
            <a href="${document.location.href}&remove-not-available-modal=1">
                Remove not available modal
            </a>
        `
        }

        return removeNotAvailableModalButtonHTML;
    }
    function _pdpInit() {
        if(document.body.classList.contains('pthclass_detalji')) {
            const allowAddToCartButtonHTML = createElementFromHTML(_allowAddToCartButtonHTML());
            if (_toolbarContent && allowAddToCartButtonHTML) {
                if (getUrlParam('allow-add-to-cart') === '1') {
                     setTimeout(() => {
                         const addToCartButton = document.querySelector('.add-to-cart');
                         if (addToCartButton) {
                             addToCartButton.classList.remove('display_none');
                         }
                     }, 1000)
                }
                _toolbarContent.appendChild(allowAddToCartButtonHTML);
            }
        }
    }

    function _cartInit() {
        if(document.body.classList.contains('pthclass_cart')) {
            const removeNotAvailableModalButtonHTML = createElementFromHTML(_removeNotAvailableModalButtonHTML());
            if (_toolbarContent && removeNotAvailableModalButtonHTML) {
                removeNotAvailableModalButtonHTML.addEventListener('click', function (e) {
                    e.preventDefault();

                    const notAvailableModal = document.querySelector('#confirm-ska');
                    if (notAvailableModal) {
                        notAvailableModal.remove();
                        const continueButtons = document.querySelectorAll('.continue-button');

                        if (continueButtons && continueButtons.length > 0 && virtualCart.process) {
                            continueButtons.forEach(continueButton => {
                                continueButton.disabled = false;
                            })
                        }
                    }


                });
                // if (getUrlParam('remove-not-available-modal') === '1') {
                //     setTimeout(() => {
                //         const addToCartButton = document.querySelector('.add-to-cart');
                //         if (addToCartButton) {
                //             addToCartButton.classList.remove('display_none');
                //         }
                //     }, 1000)
                // }
                _toolbarContent.appendChild(removeNotAvailableModalButtonHTML);
            }
        }
    }

    DebugToolbar.prototype.init = function () {

        if (_isEnabled && !_isInitialized) {
            this._createToolbar();
            this._createToolbarButton();
            this._createToolbarContent();
        }

        _isInitialized = true;
    };

    DebugToolbar.prototype._createToolbar = function () {
        _toolbar = document.createElement('div');
        _toolbar.classList.add('debug-toolbar');
        const body = document.querySelector('body');
        body.appendChild(_toolbar);
    };

    DebugToolbar.prototype._createToolbarButton = function () {
        _toolbarButton = document.createElement('button');
        _toolbarButton.classList.add('debug-toolbar__button');
        _toolbarButton.innerHTML = 'Toggle Debug Toolbar';
        _toolbarButton.addEventListener('click', () => {
            _toolbar.classList.toggle('debug-toolbar--collapsed');
            _isCollapsed = !_isCollapsed;
        });
        _toolbar.appendChild(_toolbarButton);
    };

    DebugToolbar.prototype._createToolbarContent = function () {
        _toolbarContent = document.createElement('div');
        _toolbarContent.classList.add('debug-toolbar__content');

        if (_toolbar) {
            _toolbar.appendChild(_toolbarContent);
        }
        _pdpInit();
        _cartInit();
    };


    return {
        getInstance: function () {
            if (!instance) {
                instance = new DebugToolbar();
            }
            return instance;
        }
    };
})();

export default DebugToolbar;
