import Component from '../lib/component.js';
import store from '../store/index.js';

import {cleanPrice, numberThousandSeparator} from '../../../utils';

export default class ParticipationDevice extends Component {
    constructor(selector) {
        super({
            store,
            element: document.querySelector(selector)
        });
    }
    /**
     * React to state changes and render the component's HTML
     *
     * @returns {void}
     */
    render() {
        const { participation, tariff } = store.state;
        const { participation: tariffParticipation } = tariff;

        const fullParticipation = cleanPrice(participation);
        this.element.innerHTML = `
            ${numberThousandSeparator(fullParticipation)}
        `;
    }
}
