import Component from '../lib/component.js';
import store from '../store/index.js';

import {cleanPrice, numberThousandSeparator, qs} from '../../../utils';

export default class devicePrice extends Component {
    constructor(selector) {
        super({
            store,
            element: document.querySelector(selector)
        });
    }

    getPriceMonthly = () => {
        const { tariff, instalments, participation } = store.state;
        const { price, participation: tariffParticipation } = tariff;

        const fullParticipation = cleanPrice(participation);

        return Math.round((cleanPrice(price) - fullParticipation) / instalments);
    }

    getOldPriceMonthly = () => {
        const { tariff, instalments, participation } = store.state;
        const { tariffOldPrice, participation: tariffParticipation } = tariff;

        const fullParticipation = cleanPrice(participation);

        if(tariffOldPrice !== 0 && tariffOldPrice !== '0'){
            return Math.round((cleanPrice(tariffOldPrice) - fullParticipation) / instalments);
        } else{
            return null;
        }

    }

    getPriceCash = () => {
        const { tariff } = store.state;
        const { priceCash } = tariff;

        return cleanPrice(priceCash);
    }

    getOldPriceCash = () => {
        const { tariff } = store.state;
        const { tariffOldPriceCash } = tariff;

        return cleanPrice(tariffOldPriceCash);
    }

    getPrice = () => {
        const { paymentType } = store.state;
        let price = 0;
        if (paymentType === 'rate') {
            price = this.getPriceMonthly();
        } else if (paymentType === 'gotovina') {
            price = this.getPriceCash();
        } else {
            console.warning('paymentType is not valid (STICKY STATE)');
        }


        return price;
    }

    getOldPrice = () => {
        const { tariff, instalments } = store.state;
        const { tariffOldPrice } = tariff;
        return Math.round(cleanPrice(tariffOldPrice) / instalments);
    }

    getPriceString = () => {
        const price = this.getPrice();
        const oldPriceCash = this.getOldPriceCash();
        // const oldPrice = this.getOldPrice();

        const oldPrice = this.getOldPriceMonthly();
        const { paymentType, instalments, isAccessory } = store.state;

        let accOldPriceHtml = ``;
        let xmassDiscountHtml = ``;
        if(isAccessory){
            if(oldPriceCash && paymentType === 'gotovina') {
                accOldPriceHtml = `
                    <div id="acc-old-price-discount" class="m-device-card__price-discount">
                    <span class="a-01-text__primary--xs lh-large" id="tariffPrice">${numberThousandSeparator(oldPriceCash)} RSD</span>
                </div>
                `;
            } else if(oldPrice !== 0 && oldPrice !== null) {
                accOldPriceHtml = `
                <div id="acc-old-price-discount" class="m-device-card__price-discount">
                    <span class="a-01-text__primary--xs lh-large" id="tariffPrice">${numberThousandSeparator(oldPrice)} RSD/mes</span>
                </div>
            `;
            }
        } else{
            // if(xmass2021){ //window.xmass2021 = xmass2021; a1_device_single_view.html
            if(oldPriceCash && paymentType === 'gotovina'){
                xmassDiscountHtml = `
                <div id="xmass-discount" class="m-device-card__price-discount">
                    <span class="a-01-text__primary--xs lh-large" id="tariffPrice">${numberThousandSeparator(oldPriceCash)} RSD</span>
                </div>
            `;
            } else if(price !== 1 && oldPriceCash !== 0){
                xmassDiscountHtml = `
                <div id="xmass-discount" class="m-device-card__price-discount">
                    <span class="a-01-text__primary--xs lh-large" id="tariffPrice">${numberThousandSeparator(oldPrice)} RSD/mes</span>
                </div>
            `;
            }
            // }
        }

        return `
            ${accOldPriceHtml}
            ${xmassDiscountHtml}
            ${this.isMonthly() ? instalments + " x " : ''}
            ${numberThousandSeparator(price)} RSD${this.isMonthly() ? '/mes' : ''}
        `;
    }

    isMonthly = () => {
        const { paymentType } = store.state;
        return paymentType !== 'gotovina';
    }

    /**
     * React to state changes and render the component's HTML
     *
     * @returns {void}
     */
    render() {
        this.element.innerHTML = this.getPriceString();

    }
}
