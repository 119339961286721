import Component from '../lib/component.js';
import store from '../store/index.js';

import {cleanPrice, numberThousandSeparator} from '../../../utils';

export default class AccessoriesAllPrice extends Component {
    constructor() {
        super({
            store,
            element: document.querySelector('.acc-all-price-desktop')
        });
    }

    getAccessoriesTotalPriceCash = () => {
        const { accessories, paymentType } = store.state;

        if (accessories && paymentType === 'gotovina') {
            return accessories.reduce((accumulator, currentValue) => accumulator + cleanPrice(currentValue.priceCash), 0);
        }
        return 0;
    }
    getAccessoriesTotalPriceMonthly = () => {
        const { accessories, paymentType } = store.state;

        if (accessories && paymentType === 'rate') {
            return accessories.reduce((accumulator, currentValue) => accumulator + cleanPrice(currentValue.price), 0);
        }
        return 0;
    }


    getAccessoriesTotalString = () => {
        const { paymentType, instalments } = store.state;
        let priceToShow = 0;
        let monthlyStringSuffix = '';
        if (paymentType === 'gotovina') {
            priceToShow = this.getAccessoriesTotalPriceCash();
        } else {
            priceToShow = Math.round(this.getAccessoriesTotalPriceMonthly() / instalments);
            monthlyStringSuffix = '/mes'
        }
        return `
            ${numberThousandSeparator(priceToShow)} RSD${monthlyStringSuffix}
        `;
    }


    /**
     * React to state changes and render the component's HTML
     *
     * @returns {void}
     */
    render() {

        this.element.innerHTML = this.getAccessoriesTotalString();

    }
}
