import Component from '../lib/component.js';
import store from '../store/index.js';

import { cleanPrice, numberThousandSeparator } from '../../../utils';

export default class MonthlyDevicePrice extends Component {
    constructor(selector) {
        super({
            store,
            element: document.querySelector(selector)
        });
    }

    // checkMinAndMaxForInstalments = () => {
    //     const { tariff } = store.state;
    //     const { maxParticipationForActivation, minParticipationForActivation } = tariff;
    //
    // }

    getPriceMonthly = () => {
        const { tariff, instalments, participation } = store.state;
        const { price, participation: tariffParticipation } = tariff;

        const fullParticipation = cleanPrice(participation);

        return Math.round((cleanPrice(price) - fullParticipation) / instalments);
    }

    /**
     * React to state changes and render the component's HTML
     *
     * @returns {void}
     */
    render() {
        const price = this.getPriceMonthly();

        this.element.innerHTML = `
            ${numberThousandSeparator(price)} RSD
        `;
    }
}
