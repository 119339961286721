export default {
    items: [
        'I made this',
        'Another thing'
    ],
    testString: null,
    tariff: {
        externalId: null,
        maxParticipationForActivation: null,
        minParticipationForActivation: null,
        monthlyFee: null,
        name: null,
        participation: null,
        price: null,
        priceCash: null,
        oldPrice: null,
        oldPriceCash: null,
        tariffCode: null,
        tariffName: null,
        tariffProperties: null,
        tariffOldPrice: null,
        tariffCollection: null,
        shortDescription: null,
    },
    paymentType: 'rate',
    instalments: 24,
    participation: 0,
    isTvOrTablet: false, // todo THIS IS isTvOrLaptop - NAME IS NOT VALID
    isAccessory: false,
    isBusiness: false,
    isBusinessAndTablet: false,
    insurance: {
        insuranceName: null,
        insuranceParticipation: null,
        insurancePrice: null,
        insuranceType: null,
        isSelected: false,
    },
    accessories: [],
    tariffCommunicationPromoList: [],

};
