import Component from '../lib/component.js';
import store from '../store/index.js';

export default class MonthlyTariffPrice extends Component {
    constructor() {
        super({
            store,
            element: document.querySelector('.basket-tariff-price-desktop')
        });
    }

    isStandaloneTariff = () => {
        const { tariff } = store.state;
        const { tariffCollection } = tariff;
        return tariffCollection === '5';
    }

    /**
     * React to state changes and render the component's HTML
     *
     * @returns {void}
     */
    render() {
        const { tariff } = store.state;
        const { monthlyFee, oldMonthlyDevicePrice, tariffMonthlyDevicePriceOld } = tariff;

        if (this.isStandaloneTariff()) {
            this.element.innerHTML = '';
        } else {
                if(oldMonthlyDevicePrice !== '' && oldMonthlyDevicePrice !== 0 && oldMonthlyDevicePrice !== '0'){
                    this.element.innerHTML = `
                        <div class="m-device-card__price-discount">
                            <span class="a-01-text__primary--sm lh-large " id="sticky-old-monthly">${oldMonthlyDevicePrice} RSD/mes</span>
                        </div>
                        <span id="newMonthlyDevicePrice">${monthlyFee} RSD/mes</span>
                    `;
                } else{
                    this.element.innerHTML = `
                        <span id="newMonthlyDevicePrice">${monthlyFee} RSD/mes</span>
                    `;
                }

                /* NEO15 FEB PROMO */
                if(tariff.tariffMonthlyDevicePriceOld !== 0 && tariff.tariffMonthlyDevicePriceOld !== '0' && tariff.tariffMonthlyDevicePriceOld !== '' && tariff.tariffMonthlyDevicePriceOld !== null && tariff.tariffMonthlyDevicePriceOld !== undefined) {
                    this.element.innerHTML = `
                        <div class="m-device-card__price-discount">
                            <span class="a-01-text__primary--sm lh-large font-size-discount" id="sticky-old-monthly">${tariffMonthlyDevicePriceOld} RSD/mes</span>
                        </div>
                        <span id="newMonthlyDevicePrice">${monthlyFee} RSD/mes</span>
                    `;
                }
            }

        }

}
